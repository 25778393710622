import sodium from "libsodium-wrappers-sumo";

import { RPCClient } from "../../../lib/rpc";
import {
	Box,
	KeyGenerationHelper,
	Matching,
	OPAQUE,
	OPRF,
} from "../../../lib/crypto";
import { endpoints as v1 } from "../endpoints";
import { tAdminUserData, tKey, tSodiumBytes } from "../../server/util/iots";
import sha1 from "js-sha1";
import { admin, adminUserData, dloc } from "../../../lib/data";
import { RecoveryClient } from "../../recoveryserver/client";

// This is cached here since it never ended up changing between environments.
const commonBadgeSalt = "0ddMh4J8gWtnC4Gt";

export interface NewAdminData {
	name: string;
	email: string;
}

export class AdminClient extends RPCClient {
	public contact = {
		email: "",
		name: "",
	};

	public versionedKeys: Record<
		number,
		{
			publicKey: Uint8Array;
			privateKey: Uint8Array;
		}
	> = {};

	public token: string = null;
	public envVars: Record<string, string> = {};
	public userData: adminUserData = {};
	private recoveryClient: RecoveryClient;

	constructor(baseURL?: string) {
		super();
		if (!baseURL) {
			baseURL = process.env.APP_SERVER_URL;
		}
		// TODO: move this into the base class!
		this.baseURL = baseURL !== undefined ? baseURL : "";
	}

	public async getEnvironmentVariables(): Promise<Record<string, string>> {
		this.envVars = await this.call(v1.GetFrontEndEnvironmentVariables)({});
		return this.envVars;
	}

	// Login =====================================================================
	public async login(
		username: string,
		password: string,
		badgeSalt = commonBadgeSalt,
	): Promise<void> {
		const lowercaseUsername = username.toLowerCase();
		const usernameHash = OPAQUE.makeUsername(lowercaseUsername);
		const passwordHash = OPAQUE.makePassword(password, badgeSalt);
		const alpha = OPAQUE.mask(passwordHash);

		await this.doLogin(lowercaseUsername, usernameHash, alpha);

		await this.bootstrap();
	}

	public async saveUserData(): Promise<unknown> {
		return this.encryptedCall(v1.SaveUserData)({
			data: Box.tsEncrypt(this.publicKey, this.userData, tAdminUserData),
		});
	}

	public async bootstrap(): Promise<void> {
		this.contact = await this.encryptedCall(v1.GetAdminContactInfo)({});

		const { keys } = await this.encryptedCall(v1.GetSharedKeysForAdmin)({});
		if (keys) {
			keys.forEach((key) => {
				this.versionedKeys[key.version] = {
					publicKey: key.publicKey,
					privateKey: Box.tsDecrypt(
						key.encryptedKey,
						this.publicKey,
						this.privateKey,
						tKey,
					),
				};
			});
		}

		const { encryptedUserData } = await this.encryptedCall(v1.BootstrapUser)(
			{},
		);
		if (encryptedUserData) {
			this.userData = Box.tsDecrypt(
				encryptedUserData,
				this.publicKey,
				this.privateKey,
				tAdminUserData,
			);
			if (
				!this.userData.signingKeys?.publicKey ||
				!this.userData.signingKeys?.privateKey
			) {
				const signingKeys = sodium.crypto_sign_keypair();
				this.userData.signingKeys = signingKeys;
				await this.saveUserData();
				await this.encryptedCall(v1.SaveSigningPublicKey)({
					publicKey: signingKeys.publicKey,
				});
			}
		} else {
			this.userData = {};
		}
	}

	// Account management ========================================================
	public async updateContactInfo(name: string, email: string): Promise<void> {
		if (email !== this.contact.email) {
			const currentContactName = this.contact.name;
			try {
				this.contact.name = name;
				await this.resetAccountRecovery(email, this.userData.phoneNumber);
			} catch (e) {
				this.contact.name = currentContactName;
				throw e;
			}
		} else {
			const { success } = await this.encryptedCall(v1.UpdateAdminContactInfo)({
				name,
				emailAddress: email,
			});

			if (!success) {
				throw new Error("Unknown error updating contact information.");
			}
		}
	}

	public async getContactInfo(): Promise<{
		name: string;
		email: string;
	}> {
		return await this.encryptedCall(v1.GetAdminContactInfo)({});
	}

	public async checkPasswordStrength(password: string): Promise<void> {
		if (password.length < 8) {
			throw new Error("too short");
		}

		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		const hash = sha1(password) as string;
		const prefix = hash.substr(0, 5);
		const { hashes } = await this.call(v1.CheckPasswordStrength)({ prefix });

		if (hashes.includes(hash)) {
			throw new Error("found match");
		}
	}

	public async updatePassword(
		oldPassword: string,
		newPassword: string,
		badgeSalt = commonBadgeSalt,
	): Promise<unknown> {
		// Essentially perform a login to verify that the user has entered
		// their correct password to confirm their intent.
		const usernameHash = OPAQUE.makeUsername(this.username);
		const oldPasswordHash = OPAQUE.makePassword(oldPassword, badgeSalt);
		const oldAlpha = OPAQUE.mask(oldPasswordHash);

		const loginData = await this.call(v1.Login)({
			index: usernameHash,
			alpha: oldAlpha.point,
			passwordCheck: true,
		});

		const oldUnmasked = OPAQUE.unmask(loginData.beta, oldAlpha.mask);
		OPAQUE.decrypt(oldUnmasked, loginData.encryptedEnvelope);

		// If the decryption succeeded, the user entered their correct password
		// and we should carry out the password change.

		const newPasswordHash = OPAQUE.makePassword(newPassword, badgeSalt);
		const newAlpha = OPAQUE.mask(newPasswordHash);

		const { output: beta } = await this.encryptedCall(v1.OPRF_Me)({
			alpha: newAlpha.point,
		});

		const newUnmasked = OPAQUE.unmask(beta, newAlpha.mask);
		const encrypted = OPAQUE.encrypt(newUnmasked, {
			pk: this.publicKey,
			sk: this.privateKey,
		});

		return this.encryptedCall(v1.UpdatePassword)({
			newIndex: OPAQUE.makeUsername(this.username),
			newEnvelope: encrypted,
		});
	}

	// Backup Codes ==============================================================
	public async createBackups(
		n: number,
		salt = commonBadgeSalt,
	): Promise<string[]> {
		const encryptedEnvelopes: { [index: string]: Uint8Array } = {};
		const codes: string[] = [];

		for (let i = 0; i < n; i++) {
			const backup = OPAQUE.createBackup(
				this.username,
				{
					pk: this.publicKey,
					sk: this.privateKey,
				},
				salt,
			);

			const index = sodium.to_hex(sodium.crypto_hash_sha256(backup.code));
			encryptedEnvelopes[index] = backup.encrypted;
			codes.push(backup.code);
		}

		await this.encryptedCall(v1.SaveBackupCodes)({ codes: encryptedEnvelopes });
		return codes;
	}

	public async emailBackupCodes(email: string, codes: string[]): Promise<void> {
		await this.encryptedCall(v1.MailBackupCodes)({
			email,
			codes,
		});
	}

	public async useBackupCode(
		code: string,
		salt = commonBadgeSalt,
	): Promise<void> {
		const index = sodium.to_hex(sodium.crypto_hash_sha256(code));

		const resp = await this.call(v1.UseBackupCode_Step1_Find)({ code: index });

		this.userID = resp.userID;
		this.serverKey = resp.serverPublicKey;

		// Stage all the information needed for finalizing the request.
		const decrypted = OPAQUE.decryptBackup(resp.encryptedEnvelope, code, salt);
		this.username = decrypted.username;
		this.publicKey = decrypted.keys.pk;
		this.privateKey = decrypted.keys.sk;
	}

	public async burnBackupCode(
		code: string,
		newPassword: string,
		badgeSalt: string = commonBadgeSalt,
	): Promise<unknown> {
		const codeIndex = sodium.to_hex(sodium.crypto_hash_sha256(code));

		const passwordHash = OPAQUE.makePassword(newPassword, badgeSalt);

		const alpha = OPAQUE.mask(passwordHash);
		const { output: beta } = await this.encryptedCall(v1.OPRF_Me)({
			alpha: alpha.point,
		});

		const unmasked = OPAQUE.unmask(beta, alpha.mask);
		const encrypted = OPAQUE.encrypt(unmasked, {
			pk: this.publicKey,
			sk: this.privateKey,
		});

		return this.encryptedCall(v1.UseBackupCode_Step3_Finalize)({
			encryptedEnvelope: encrypted,
			codeIndex,
		});
	}

	public async deactivateDloc(
		dlocId: string,
		dlocEmail: string,
		dlocPhone: string,
	): Promise<void> {
		const recoveryClient = await this.getRecoveryClient();
		try {
			await recoveryClient.deleteByProxy(
				dlocEmail,
				dlocPhone,
				this.userData.signingKeys?.privateKey,
				this.userID,
				"d",
			);
		} catch (error) {
			try {
				await this.submitEvent("Delete DLOC recovery data", {
					error: (error as Error).message,
				});
			} catch {
				// Ignore this error
			}

			throw new Error(
				"Error deleting the DLOC's recovery data. Please try again later.",
			);
		}

		let dlocDeactivatedSuccessfully = false;
		try {
			const { success } = await this.encryptedCall(v1.AdminDeactivateDloc)({
				dlocId,
			});
			dlocDeactivatedSuccessfully = success;
		} catch (e) {
			try {
				await this.submitEvent("Deactivate DLOC", {
					error: (e as Error).message,
					dlocId,
				});
			} catch {
				// Ignore this error
			}
			throw new Error(
				"Error deactivating the DLOC. Please contact tech support for assistance.",
			);
		}

		if (!dlocDeactivatedSuccessfully) {
			throw new Error(
				"DLOC not deactivated. Please contact tech support for assistance.",
			);
		}
	}

	public async deactivateAdmin(
		adminId: string,
		adminEmail: string,
		adminPhone: string,
	): Promise<void> {
		const recoveryClient = await this.getRecoveryClient();
		try {
			await recoveryClient.deleteByProxy(
				adminEmail,
				adminPhone,
				this.userData.signingKeys?.privateKey,
				this.userID,
				"a",
			);
		} catch (error) {
			try {
				await this.submitEvent("Delete Admin recovery data", {
					error: (error as Error).message,
				});
			} catch {
				// Ignore this error
			}

			throw new Error(
				"Error deleting the Admin's recovery data. Please try again later.",
			);
		}

		let adminDeactivatedSuccessfully = false;
		try {
			const { success } = await this.encryptedCall(v1.AdminDeactivateAdmin)({
				adminId,
			});
			adminDeactivatedSuccessfully = success;
		} catch (e) {
			try {
				await this.submitEvent("Deactivate Admin", {
					error: (e as Error).message,
					adminId,
				});
			} catch {
				// Ignore this error
			}
			throw new Error(
				"Error deactivating the Admin. Please contact tech support for assistance.",
			);
		}

		if (!adminDeactivatedSuccessfully) {
			throw new Error(
				"Admin not deactivated. Please contact tech support for assistance.",
			);
		}
	}

	// Account creation
	public async inviteNewAdmin(
		adminData?: NewAdminData,
		adminId?: string,
	): Promise<boolean> {
		const { success } = await this.encryptedCall(v1.SendAdminInvitation)({
			data: adminData,
			id: adminId,
		});

		return success;
	}

	public async validateAccountToken(token: string): Promise<NewAdminData> {
		return await this.call(v1.ValidateAdminAccountToken)({ token });
	}

	public async registerAccount(
		username: string,
		password: string,
		token: string = this.token,
		badgeSalt: string = commonBadgeSalt,
	): Promise<void> {
		let encryptedKeys: Uint8Array;
		try {
			const lowercaseUsername = username.toLowerCase();
			const usernameHash = OPAQUE.makeUsername(lowercaseUsername);
			const passwordHash = OPAQUE.makePassword(password, badgeSalt);
			const alpha = OPAQUE.mask(passwordHash);
			const keys = OPAQUE.generateKeys();

			const oprfData = await this.call(v1.CreateAccount_Step1_OPRF)({
				token,
				index: usernameHash,
				alpha: alpha.point,
				userPublicKey: keys.pk,
				privacyPolicyAccepted: null,
				campusName: null,
				emailDomain: null,
			});

			const unmasked = OPAQUE.unmask(oprfData.beta, alpha.mask);
			encryptedKeys = OPAQUE.encrypt(unmasked, keys);

			this.username = lowercaseUsername;
			this.userID = oprfData.userID;
			this.serverKey = oprfData.serverPublicKey;
			this.publicKey = keys.pk;
			this.privateKey = keys.sk;
		} catch (error) {
			const data = {
				token,
				success: false,
				error: (error as Error).message,
			};

			try {
				await this.submitEvent("Register admin account", data);
			} catch {
				// Swallow it
			}
			throw error;
		}

		try {
			await this.encryptedCall(v1.CreateAccount_Step2_Finalize)({
				envelope: encryptedKeys,
			});
		} catch (err) {
			try {
				await this.submitEvent("Create account step 2", {
					error: (err as Error).message,
				});
			} catch {
				// fail silently
			}

			try {
				await this.encryptedCall(v1.UndoCreateAccountStep1)({});
			} catch (e) {
				try {
					await this.submitEvent("Roll back create account step 1", {
						error: (e as Error).message,
					});
				} catch {
					// fail silently
				}
				throw new Error(
					"Error creating account. Please ask an existing Admin to restart the process.",
				);
			} finally {
				await this.logout();
			}
			throw new Error("Error creating account. Please try submitting again.");
		}
	}

	public async activateNewAdmin(newAdminId: string): Promise<void> {
		const { adminPublicKey } = await this.encryptedCall(v1.GetAdminPublicKey)({
			adminId: newAdminId,
		});

		const encryptedVersionedKeys: {
			version: number;
			key: Uint8Array;
		}[] = [];
		Object.keys(this.versionedKeys).forEach((version) => {
			const { privateKey } = this.versionedKeys[version] as {
				publicKey: Uint8Array;
				privateKey: Uint8Array;
			};
			const encryptedPrivateKey = Box.tsEncrypt(
				adminPublicKey,
				privateKey,
				tKey,
			);
			encryptedVersionedKeys.push({
				version: parseInt(version, 10),
				key: encryptedPrivateKey,
			});
		});

		const { success } = await this.encryptedCall(
			v1.SaveVersionedKeysForNewAdmin,
		)({
			adminId: newAdminId,
			keys: encryptedVersionedKeys,
		});

		if (!success) {
			throw new Error("Error activating the new admin.");
		}
	}

	// Key management
	public async generateSharedKey(): Promise<void> {
		try {
			const { keys } = await this.encryptedCall(v1.GetAdminKeys)({});
			const newKeyVersion = this.getLatestSharedKeyVersion() + 1;

			const sharedKeys = KeyGenerationHelper.generateSharedKeys();
			const encryptedSecretKeys = KeyGenerationHelper.encryptSharedKeys(
				keys,
				sharedKeys.privateKey,
			);
			await this.encryptedCall(v1.SaveVersionedSharedAdminKeys)({
				version: newKeyVersion,
				sharedPublicKey: sharedKeys.publicKey,
				encryptedSecretKeys: encryptedSecretKeys.map(
					({ userId, encryptedKey }) => ({
						adminId: userId,
						encryptedKey,
					}),
				),
			});
			this.versionedKeys[newKeyVersion] = {
				publicKey: sharedKeys.publicKey,
				privateKey: sharedKeys.privateKey,
			};
		} catch (error) {
			const data = {
				success: false,
				error: (error as Error).message,
			};
			try {
				await this.submitEvent(
					"Generate New Version of Shared Admin Keys",
					data,
				);
			} catch {
				// Swallow it
			}

			throw new Error("Key generation failed");
		}
	}

	// Miscellaneous
	public async getAllAdmins(): Promise<admin[]> {
		const { admins } = await this.encryptedCall(v1.GetAllAdmins)({});
		return admins;
	}

	public async getAllDlocs(): Promise<dloc[]> {
		const { dlocs } = await this.encryptedCall(v1.GetAllDlocs)({});
		return dlocs;
	}

	// Sending broadcast messages
	public async sendGeneralInformationMessage(
		subject: string,
		message: string,
		matchedOnly: boolean,
	): Promise<boolean> {
		const { encryptedEmailAddresses } = await this.encryptedCall(
			v1.GetSurvivorEmailsForBroadcast,
		)({
			matchedOnly,
		});
		const { success } = await this.sendBroadcastMessage(
			encryptedEmailAddresses,
			"information",
			subject,
			message,
		);
		if (!success) {
			throw new Error(
				"Unknown error sending broadcast message. Please try again.",
			);
		}

		return success;
	}

	public async sendFeedbackBroadcastMessage(
		subject: string,
		message: string,
		matchedOnly: boolean,
	): Promise<boolean> {
		const { encryptedEmailAddresses } = await this.encryptedCall(
			v1.GetSurvivorEmailsForFeedback,
		)({
			matchedOnly,
		});
		const { success } = await this.sendBroadcastMessage(
			encryptedEmailAddresses,
			"feedback",
			subject,
			message,
		);
		if (!success) {
			throw new Error(
				"Unknown error sending broadcast message. Please try again.",
			);
		}

		return success;
	}

	// Account Recovery
	public async getRecoveryClient(): Promise<RecoveryClient> {
		if (this.recoveryClient !== undefined) {
			return this.recoveryClient;
		}

		const env = await this.getEnvironmentVariables();
		this.recoveryClient = new RecoveryClient(
			env.UI_ENV_RECOVERY_SERVER_1_URL,
			env.UI_ENV_RECOVERY_SERVER_2_URL,
		);

		return this.recoveryClient;
	}

	public async verifyAccountRecoveryToken(token: string): Promise<{
		success: boolean;
		questions: string[];
	}> {
		const client = await this.getRecoveryClient();
		const questions = await client.recovery_step1_get_questions(token);

		return Promise.resolve({
			success: true,
			questions,
		});
	}

	public async validateSecurityQuestionAnswers(
		answers: string[],
		token: string,
	): Promise<boolean> {
		try {
			const client = await this.getRecoveryClient();
			const envelope = await client.recovery_step2_complete(token, answers);

			const { serverKey } = await this.call(v1.GetServerPublicKey)({});
			this.userID = envelope.userID;
			this.username = envelope.username;
			this.publicKey = envelope.envelope.pk;
			this.privateKey = envelope.envelope.sk;
			this.serverKey = serverKey;
			return true;
		} catch (e) {
			try {
				const data = {
					error: (e as Error).message,
				};
				await this.submitEvent("Validate security question answers", data);
			} catch (error) {
				// Swallow this error
			}
			return false;
		}
	}

	public async submitAccountRecoveryRequest(
		email: string,
		phoneNumber: string,
		recoveryKey: "a" | "d",
	): Promise<{ success: boolean }> {
		const mailDomainKeys: Record<string, string> = {
			a: "MAIL_ADMIN_CANONICAL_DOMAIN",
			d: "MAIL_DLOC_CANONICAL_DOMAIN",
		};

		try {
			const client = await this.getRecoveryClient();

			await client.request(
				email,
				phoneNumber,
				mailDomainKeys[recoveryKey],
				recoveryKey,
			);
		} catch (error) {
			try {
				let type = "DLOC";
				if (recoveryKey === "a") {
					type = "Admin";
				}

				const data = {
					error: (error as Error).message,
				};
				await this.submitEvent(`Admin submit ${type} recovery request`, data);
			} catch {
				// Swallow this error
			}
			return { success: false };
		}

		return { success: true };
	}

	public async submitEvent(action: string, data: Record<string, unknown>) {
		const event = {
			action,
			...data,
			service_name: "admin",
			name: action,
		};
		await this.call(v1.HoneycombEvent)({ event });
	}

	public async setUpAccountRecovery(
		email: string,
		phoneNumber: string,
		securityQuestions: string[],
		answers: string[],
	): Promise<{ success: boolean }> {
		const { isActive } = await this.encryptedCall(
			v1.GetCurrentUserActiveStatus,
		)({});
		if (!isActive) {
			throw new Error(
				"Cannot set up account recovery data; account is not active",
			);
		}

		const questions = new Map<string, string>();
		for (let i = 0; i < securityQuestions.length; i++) {
			questions.set(securityQuestions[i], answers[i]);
		}

		if (!this.userData) {
			this.userData = {};
		}

		let marker: Uint8Array;
		if (this.userData.marker) {
			marker = this.userData.marker;
		} else {
			marker = sodium.randombytes_buf(64);
			this.userData.marker = marker;
		}

		// Create an ownership key for this entry so that we can delete it later
		// if we need to.
		const keys = Matching.makeOwnershipKey();
		this.userData.recoveryOwnershipKey = keys.privateKey;
		await this.saveUserData();

		const recoveryClient = await this.getRecoveryClient();
		await recoveryClient.setup(
			email,
			phoneNumber,
			questions,
			this.userID,
			this.username,
			marker,
			{
				pk: this.publicKey,
				sk: this.privateKey,
			},
			keys.publicKey,
			"a",
		);
		await this.updateAccountRecoveryData(
			email,
			phoneNumber,
			securityQuestions,
			answers,
		);
		return { success: true };
	}

	public async updateAccountRecoveryData(
		email: string,
		phone: string,
		securityQuestions: string[] = this.userData.securityQuestions,
		answers: string[] = this.userData.securityAnswers,
	): Promise<void> {
		try {
			if (email !== this.contact.email) {
				if (!this.serverKey) {
					const { serverKey } = await this.call(v1.GetServerPublicKey)({});
					this.serverKey = serverKey;
				}

				await this.encryptedCall(v1.UpdateAdminContactInfo)({
					emailAddress: email,
					name: this.contact.name,
				});

				this.contact.email = email;

				try {
					const data = {
						success: true,
					};
					await this.submitEvent("Update Admin email", data);
				} catch {
					// Swallow this error
				}
			}

			this.userData.securityQuestions = securityQuestions;
			this.userData.securityAnswers = answers;
			this.userData.phoneNumber = phone;
			await this.saveUserData();
		} catch (error) {
			try {
				const data = {
					success: false,
					error: (error as Error).message,
				};
				await this.submitEvent("Admin update account recovery data", data);
			} catch {
				// Swallow this error
			}
			throw new Error(
				"There was an error updating your account information; please try again",
			);
		}
	}

	public async resetAccountRecovery(
		email: string,
		phoneNumber: string,
		securityQuestions: string[] = this.userData.securityQuestions,
		answers: string[] = this.userData.securityAnswers,
	): Promise<{ success: boolean }> {
		const { isActive } = await this.encryptedCall(
			v1.GetCurrentUserActiveStatus,
		)({});
		if (!isActive) {
			throw new Error(
				"Cannot update account recovery data; account is not active",
			);
		}

		const questions = new Map<string, string>();
		for (let i = 0; i < securityQuestions.length; i++) {
			questions.set(securityQuestions[i], answers[i]);
		}
		const client = await this.getRecoveryClient();
		try {
			await client.update(
				this.contact.email,
				this.userData.phoneNumber,
				email,
				phoneNumber,
				questions,
				this.userID,
				this.username,
				this.userData.marker,
				{
					pk: this.publicKey,
					sk: this.privateKey,
				},
				this.userData.recoveryOwnershipKey,
				"a",
			);
			await this.updateAccountRecoveryData(
				email,
				phoneNumber,
				securityQuestions,
				answers,
			);
		} catch (e) {
			try {
				const data = {
					error: (e as Error).message,
				};
				await this.submitEvent("Admin reset account recovery", data);
			} catch {
				// swallow this error
			}
			throw new Error(
				"There was an error updating your data. Please try again.",
			);
		}
		return { success: true };
	}

	private async sendBroadcastMessage(
		encryptedEmailAddresses: {
			keyVersion: number;
			encryptedEmail: Uint8Array;
		}[],
		messageType: string,
		subject: string,
		message: string,
	) {
		const partiallyDecryptedEmailAddresses: Uint8Array[] =
			encryptedEmailAddresses.map((encryptedEmail) => {
				const keys = this.versionedKeys[encryptedEmail.keyVersion];
				return Box.tsDecrypt(
					encryptedEmail.encryptedEmail,
					keys.publicKey,
					keys.privateKey,
					tSodiumBytes,
				);
			});

		return await this.encryptedCall(v1.SendBroadcastMessage)({
			encryptedEmailAddresses: partiallyDecryptedEmailAddresses,
			type: messageType,
			subject,
			message,
		});
	}
	private async doLogin(username: string, index: string, alpha: OPRF.Alpha) {
		const oprfData = await this.call(v1.Login)({
			index,
			alpha: alpha.point,
			passwordCheck: false,
		});

		const unmasked = OPAQUE.unmask(oprfData.beta, alpha.mask);
		const decrypted = OPAQUE.decrypt(unmasked, oprfData.encryptedEnvelope);

		this.username = username;
		this.userID = oprfData.userID;
		this.serverKey = oprfData.serverPublicKey;
		this.publicKey = decrypted.pk;
		this.privateKey = decrypted.sk;
	}

	private getLatestSharedKeyVersion = () => {
		if (Object.keys(this.versionedKeys).length > 0) {
			const keyVersions = Object.keys(this.versionedKeys);
			const maxSharedKeyVersion = keyVersions.sort(
				(a, b) => parseInt(b, 10) - parseInt(a, 10),
			)[0];
			return parseInt(maxSharedKeyVersion, 10);
		} else {
			return 0;
		}
	};
}
