import * as t from "io-ts";
import { tSodiumBytes, tSodiumDate } from "./util/iots";

export const adminRpcSpec = {
	UpdateAdminContactInfo: {
		input: t.type({
			name: t.string,
			emailAddress: t.string,
		}),
		output: t.type({
			success: t.boolean,
		}),
	},

	GetAdminContactInfo: {
		input: t.unknown,
		output: t.type({
			name: t.string,
			email: t.string,
		}),
		XXX_THINK_CAREFULLY_no_token: true,
	},

	GetAllAdmins: {
		input: t.unknown,
		output: t.type({
			admins: t.array(
				t.type({
					id: t.string,
					name: t.string,
					email: t.string,
					isRegistered: t.boolean,
					isActivated: t.boolean,
					isActive: t.boolean,
					dateDeactivated: t.union([tSodiumDate, t.null]),
				}),
			),
		}),
		XXX_THINK_CAREFULLY_no_token: true,
	},

	SendAdminInvitation: {
		input: t.type({
			id: t.union([t.string, t.undefined]),
			data: t.union([
				t.type({
					name: t.string,
					email: t.string,
				}),
				t.null,
			]),
		}),
		output: t.type({
			success: t.boolean,
		}),
	},

	ValidateAdminAccountToken: {
		input: t.type({
			token: t.string,
		}),
		output: t.type({
			name: t.string,
			email: t.string,
		}),
	},

	GetSharedKeysForAdmin: {
		input: t.unknown,
		output: t.type({
			keys: t.array(
				t.type({
					version: t.number,
					encryptedKey: tSodiumBytes,
					publicKey: tSodiumBytes,
				}),
			),
		}),
		XXX_THINK_CAREFULLY_no_token: true,
	},

	GetAdminKeys: {
		input: t.unknown,
		output: t.type({
			keys: t.record(t.string, tSodiumBytes),
		}),
		XXX_THINK_CAREFULLY_no_token: true,
	},

	SaveVersionedSharedAdminKeys: {
		input: t.type({
			version: t.number,
			sharedPublicKey: tSodiumBytes,
			encryptedSecretKeys: t.array(
				t.type({
					adminId: t.string,
					encryptedKey: tSodiumBytes,
				}),
			),
		}),
		output: t.type({
			success: t.boolean,
		}),
	},

	GetAdminPublicKey: {
		input: t.type({
			adminId: t.string,
		}),
		output: t.type({
			adminPublicKey: tSodiumBytes,
		}),
		XXX_THINK_CAREFULLY_no_token: true,
	},

	SaveVersionedKeysForNewAdmin: {
		input: t.type({
			adminId: t.string,
			keys: t.array(
				t.type({
					version: t.number,
					key: tSodiumBytes,
				}),
			),
		}),
		output: t.type({
			success: t.boolean,
		}),
	},

	GetSurvivorEmailsForBroadcast: {
		input: t.type({
			matchedOnly: t.boolean,
		}),
		output: t.type({
			encryptedEmailAddresses: t.array(
				t.type({
					keyVersion: t.number,
					encryptedEmail: tSodiumBytes,
				}),
			),
		}),
		XXX_THINK_CAREFULLY_no_token: true,
	},

	GetSurvivorEmailsForFeedback: {
		input: t.type({
			matchedOnly: t.boolean,
		}),
		output: t.type({
			encryptedEmailAddresses: t.array(
				t.type({
					keyVersion: t.number,
					encryptedEmail: tSodiumBytes,
				}),
			),
		}),
		XXX_THINK_CAREFULLY_no_token: true,
	},

	SendBroadcastMessage: {
		input: t.type({
			encryptedEmailAddresses: t.array(tSodiumBytes),
			type: t.string,
			subject: t.string,
			message: t.string,
		}),
		output: t.type({
			success: t.boolean,
		}),
	},

	AdminDeactivateDloc: {
		input: t.type({
			dlocId: t.string,
		}),
		output: t.type({
			success: t.boolean,
		}),
	},

	AdminDeactivateAdmin: {
		input: t.type({
			adminId: t.string,
		}),
		output: t.type({
			success: t.boolean,
		}),
	},
};
