/* eslint-disable react/react-in-jsx-scope */
import { h } from "preact";
import { useEffect, useState } from "preact/hooks";
import * as emailValidator from "email-validator";
import { NewAdminData } from "../../../../client";
import { Modal } from "../../../../../../lib/components/modal";

import "./styles.scss";

export const AdminInviteModal = ({
	onClose,
	onInvite,
}: {
	onClose: () => void;
	onInvite: (data: NewAdminData) => void;
}): JSX.Element => {
	const [validEmail, setValidEmail] = useState<boolean>(false);
	const [formFilled, setFormFilled] = useState<boolean>(false);
	const [formState, setFormState] = useState<NewAdminData>({
		name: "",
		email: "",
	});

	useEffect(() => {
		setFormFilled(formState.name !== "" && formState.email !== "");
		setValidEmail(emailValidator.validate(formState.email));
	}, [formState]);

	return (
		// eslint-disable-next-line @typescript-eslint/require-await
		<Modal closeFunc={async () => onClose()} canExit={true}>
			<div id="adminInviteModal">
				<h3>Add a new Admin</h3>
				<div>
					<label htmlFor="nameInput">Admin Name</label>
					<input
						id="nameInput"
						type="text"
						onChange={(e) => {
							setFormState({
								...formState,
								name: e.currentTarget.value.trim(),
							});
						}}
					/>

					<label htmlFor="emailInput">Admin Email</label>
					<input
						id="emailInput"
						type="text"
						onChange={(e) => {
							setFormState({
								...formState,
								email: e.currentTarget.value.trim(),
							});
						}}
					/>
				</div>
			</div>
			{!validEmail && formState.email !== "" && (
				<div className="pwerror">Email address is invalid</div>
			)}
			<div id="buttonDiv" className="topPadding">
				<button className="secondary" onClick={onClose}>
					Cancel
				</button>
				<button
					disabled={!(formFilled && validEmail)}
					className={formFilled && validEmail ? "primary" : "secondary"}
					onClick={() => onInvite(formState)}
				>
					Send Invitation
				</button>
			</div>
		</Modal>
	);
};
