/* eslint-disable react/react-in-jsx-scope */
import { Fragment, h } from "preact";
import { useContext, useEffect, useState } from "preact/hooks";
import { useAdmins } from "../../adminData";
import { AdminInviteModal } from "../../components/admin-invite-modal";
import { NewAdminData } from "../../../../client";
import { Toast } from "../../../../../../lib/components/toast";
import { AdminClientContext } from "../../components/AdminClientContext";
import { admin as adminType, formatDate } from "../../../../../../lib/data";
import { SubmitAccountRecoveryRequest } from "../../../../../../lib/components/SubmitAccountRecoveryRequest";

import "./styles.scss";
import "../../../styles.scss";
import {
	ConfirmDeactivation,
	UserForDeactivation,
} from "../../../../../../lib/components/ConfirmDeactivation";

// eslint-disable-next-line no-empty-pattern
export const Admins = ({}: { path?: string }): h.JSX.Element => {
	const {
		admins,
		inviteNewAdmin,
		activateAdmin,
		refreshAdmins,
		resendInvitation,
		isActivated,
	} = useAdmins();
	const [activeAdmins, setActiveAdmins] = useState<adminType[]>([]);
	const client = useContext(AdminClientContext);
	const [showAdminInviteModal, setShowAdminInviteModal] =
		useState<boolean>(false);
	const [showNewAdminInvitationEmailSent, setShowNewAdminInvitationEmailSent] =
		useState<boolean>(false);
	const [isErrorState, setIsErrorState] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [adminToRecoverAccountFor, setAdminToRecoverAccountFor] = useState<
		adminType | undefined
	>();
	const [adminToDeactivate, setAdminToDeactivate] = useState<
		adminType | undefined
	>();
	const [userToDeactivate, setUserToDeactivate] = useState<
		UserForDeactivation | undefined
	>();
	const [showAccountRecoverySuccess, setShowAccountRecoverySuccess] =
		useState<boolean>(false);
	const [showAccountRecoveryError, setShowAccountRecoveryError] =
		useState<boolean>(false);
	const [accountDeactivationError, setAccountDeactivationError] =
		useState<string>("");
	const [showAccountDeactivationSuccess, setShowAccountDeactivationSuccess] =
		useState<boolean>(false);

	useEffect(() => {
		setActiveAdmins(
			admins.filter(
				(admin) =>
					admin.isActive && admin.isActivated && !admin.dateDeactivated,
			),
		);
	}, [admins]);

	const submitRecoveryRequest = async (email: string, phone: string) => {
		let loader: HTMLElement;
		try {
			loader = document.getElementById("loaderWrapper");
			if (loader) {
				loader.style.display = "flex";
			}
			setAdminToRecoverAccountFor(undefined);
			const { success } = await client.submitAccountRecoveryRequest(
				email,
				phone,
				"a",
			);
			setShowAccountRecoverySuccess(success);
			setShowAccountRecoveryError(!success);
		} finally {
			if (loader) {
				loader.style.display = "none";
			}
		}
	};

	const activateNewAdmin = async (adminId: string) => {
		try {
			await activateAdmin(adminId);
			refreshAdmins();
		} catch (error) {
			setIsErrorState(true);
			setErrorMessage((error as Error).message);
		}
	};

	const handleInviteNewAdmin = async (formData: NewAdminData) => {
		try {
			const response = await inviteNewAdmin(formData);
			setShowNewAdminInvitationEmailSent(response);
			setIsErrorState(!response);
			if (!response) {
				setErrorMessage("Error sending invitation");
			} else {
				refreshAdmins();
			}
		} catch (error) {
			setIsErrorState(true);
			setErrorMessage((error as Error).message);
		} finally {
			setShowAdminInviteModal(false);
		}
	};

	const handleResendInvitation = async (adminId: string) => {
		try {
			const response = await resendInvitation(adminId);
			setShowNewAdminInvitationEmailSent(response);
			setIsErrorState(!response);
			if (!response) {
				setErrorMessage("Error sending invitation");
			} else {
				refreshAdmins();
			}
		} catch (error) {
			setIsErrorState(true);
			setErrorMessage((error as Error).message);
		}
	};

	const deactivateAdmin = async (email: string, phone: string) => {
		let loader: HTMLElement;
		try {
			loader = document.getElementById("loaderWrapper");
			if (loader) {
				loader.style.display = "flex";
			}
			const adminId = userToDeactivate.id;
			setUserToDeactivate(undefined);
			await client.deactivateAdmin(adminId, email, phone);
			setShowAccountDeactivationSuccess(true);
		} catch (e) {
			setAccountDeactivationError((e as Error).message);
		} finally {
			if (loader) {
				loader.style.display = "none";
			}
			refreshAdmins();
		}
	};

	return (
		<Fragment>
			<div id="loaderWrapper">
				<div className="loaderContainer">
					<div className="loader" />
				</div>
			</div>

			<main id="adminMain">
				<div id="adminCard">
					<h1>All Admins</h1>
					{isActivated(client.userID) && (
						<button
							className="primary"
							onClick={() => {
								setShowAdminInviteModal(true);
							}}
						>
							Invite New Admin
						</button>
					)}
					<div id="adminTable">
						<table>
							<tbody>
								<tr id="adminTableHeader">
									<th>ID</th>
									<th>Name</th>
									<th>Email Address</th>
									<th>Status</th>
									<th>Action</th>
								</tr>
								{admins.map((admin) => (
									<tr className="record" key={admin.id}>
										<td>{admin.id}</td>
										<td>{admin.name}</td>
										<td>{admin.email}</td>
										{admin.isActivated && (
											<Fragment>
												<td>{admin.isActive ? "Active" : "Inactive"}</td>
												<td className={admin.isActive ? "moreIcon" : ""}>
													{admin.isActive && (
														<Fragment>
															<img
																src={
																	require("../../../assets/icons/more.svg") as string
																}
																alt="moreIcon"
															/>
															<div className="moreDropdown">
																<button
																	onClick={() => {
																		setAdminToRecoverAccountFor(admin);
																	}}
																>
																	Recover Account
																</button>
																<button
																	onClick={() => {
																		setAdminToDeactivate(admin);
																	}}
																	disabled={
																		admin.id === client.userID ||
																		activeAdmins.length <= 2
																	}
																>
																	DeactivateAccount
																</button>
															</div>
														</Fragment>
													)}
												</td>
											</Fragment>
										)}
										{admin.dateDeactivated && (
											<Fragment>
												<td>
													Deactivated
													<br />
													{formatDate(admin.dateDeactivated)}
												</td>
												<td></td>
											</Fragment>
										)}
										{!admin.dateDeactivated &&
											!admin.isActivated &&
											admin.isRegistered && (
												<Fragment>
													<td>Needs activation</td>
													<td>
														{isActivated(client.userID) && (
															<button
																className="primary"
																onClick={() => {
																	void activateNewAdmin(admin.id);
																}}
															>
																Activate
															</button>
														)}
													</td>
												</Fragment>
											)}
										{!admin.dateDeactivated &&
											!admin.isActivated &&
											!admin.isRegistered && (
												<Fragment>
													<td>Not registered</td>
													<td>
														{isActivated(client.userID) && (
															<button
																onClick={() => {
																	void handleResendInvitation(admin.id);
																}}
															>
																Resend invitation
															</button>
														)}
													</td>
												</Fragment>
											)}
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</main>

			{showAdminInviteModal && (
				<AdminInviteModal
					onClose={() => {
						setShowAdminInviteModal(false);
					}}
					onInvite={(formData) => {
						void handleInviteNewAdmin(formData);
					}}
				/>
			)}
			{showNewAdminInvitationEmailSent && (
				<Toast
					error={false}
					message="Invitation email sent"
					closeFunc={async () =>
						Promise.resolve(setShowNewAdminInvitationEmailSent(false))
					}
				/>
			)}
			{isErrorState && (
				<Toast
					error={true}
					message={errorMessage}
					closeFunc={async () => Promise.resolve(setIsErrorState(false))}
				/>
			)}

			{adminToRecoverAccountFor && (
				<SubmitAccountRecoveryRequest
					onCancel={() => {
						setAdminToRecoverAccountFor(undefined);
					}}
					onSubmit={submitRecoveryRequest}
					name={adminToRecoverAccountFor.name}
					emailAddress={adminToRecoverAccountFor.email}
				/>
			)}

			{showAccountRecoveryError && (
				<Toast
					error={true}
					message="There was an error attempting to send account recovery email. Please try again."
					closeFunc={async () => {
						setShowAccountRecoveryError(false);
						return Promise.resolve();
					}}
				/>
			)}

			{showAccountRecoverySuccess && (
				<Toast
					error={false}
					message="Account recovery email sent"
					closeFunc={async () => {
						setShowAccountRecoverySuccess(false);
						return Promise.resolve();
					}}
				/>
			)}

			{adminToDeactivate && (
				<ConfirmDeactivation
					onCancel={() => {
						setAdminToDeactivate(undefined);
					}}
					onConfirm={(user: UserForDeactivation) => {
						setUserToDeactivate(user);
						setAdminToDeactivate(undefined);
					}}
					user={{
						id: adminToDeactivate.id,
						name: adminToDeactivate.name,
						emailAddress: adminToDeactivate.email,
					}}
				/>
			)}

			{userToDeactivate && (
				<SubmitAccountRecoveryRequest
					onCancel={() => {
						setUserToDeactivate(undefined);
					}}
					onSubmit={deactivateAdmin}
					name={userToDeactivate.name}
					emailAddress={userToDeactivate.emailAddress}
				/>
			)}

			{accountDeactivationError && (
				<Toast
					error={true}
					message={accountDeactivationError}
					closeFunc={async () => {
						setAccountDeactivationError("");
						return Promise.resolve();
					}}
				/>
			)}

			{showAccountDeactivationSuccess && (
				<Toast
					error={false}
					message="Account deactivated"
					closeFunc={async () => {
						setShowAccountDeactivationSuccess(false);
						return Promise.resolve();
					}}
				/>
			)}
		</Fragment>
	);
};
