/* eslint-disable react/react-in-jsx-scope */
import { Fragment, h } from "preact";
import { useContext, useState } from "preact/hooks";
import { AdminClientContext } from "../../components/AdminClientContext";
import { Toast } from "../../../../../../lib/components/toast";

import "./styles.scss";
import { Loader } from "../../../../../../lib/components/Loader";

// eslint-disable-next-line no-empty-pattern
export const ComposeEmail = ({}: { path?: string }): h.JSX.Element => {
	const client = useContext(AdminClientContext);
	const [messageType, setMessageType] = useState<string>("information");
	const [subject, setSubject] = useState<string>("");
	const [message, setMessage] = useState<string>("");
	const [matchedOnly, setMatchedONly] = useState<boolean>(false);
	const [success, setSuccess] = useState<boolean>(false);
	const [inErrorState, setInErrorState] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [showLoader, setShowLoader] = useState<boolean>(false);

	const sendEmail = async () => {
		setShowLoader(true);

		try {
			switch (messageType) {
				case "information":
					await client.sendGeneralInformationMessage(
						subject,
						message,
						matchedOnly,
					);
					break;
				case "feedback":
					await client.sendFeedbackBroadcastMessage(
						subject,
						message,
						matchedOnly,
					);
					break;
				default:
					setInErrorState(true);
					setErrorMessage("Unrecognized target audience");
					return;
			}

			setSuccess(true);
		} catch (error) {
			setInErrorState(true);
			setErrorMessage((error as Error).message);
		} finally {
			setShowLoader(false);
		}
	};

	return (
		<Fragment>
			<main id="composeEmailMain">
				<div id="composeEmailCard">
					<h1>Compose Email</h1>

					<p>
						<input
							id="matched"
							type="checkbox"
							required={false}
							onChange={(e) => {
								e.preventDefault();
								setMatchedONly(e.currentTarget.checked);
							}}
						/>{" "}
						<label htmlFor="matched">Send to matched survivors only</label>
					</p>

					<label htmlFor="messageType">Message type </label>
					<p>
						<select
							id="messageType"
							required={true}
							onChange={(e) => {
								e.preventDefault();
								setMessageType(e.currentTarget.value);
							}}
						>
							<option value="information">
								Informational (no feedback requested)
							</option>
							<option value="feedback">Survey or other feedback request</option>
						</select>
					</p>

					<label htmlFor="subject">Subject: </label>
					<p>
						<input
							id="subject"
							type="text"
							required={true}
							onChange={(e) => {
								e.preventDefault();
								setSubject(e.currentTarget.value);
							}}
						/>
					</p>

					<br />

					<label htmlFor="message">Message: </label>
					<p>
						<textarea
							id="message"
							onChange={(e) => {
								e.preventDefault();
								setMessage(e.currentTarget.value);
							}}
						/>
					</p>

					<button
						className={
							messageType && subject && message ? "btn primary" : "btn"
						}
						disabled={!(messageType && subject && message)}
						onClick={async (e) => {
							e.preventDefault();
							await sendEmail();
						}}
					>
						Send Email
					</button>

					{success && (
						<Toast
							error={false}
							message={"Email sent successfully"}
							closeFunc={async () => {
								setSuccess(false);
								return Promise.resolve();
							}}
						/>
					)}

					{inErrorState && (
						<Toast
							error={true}
							message={errorMessage}
							closeFunc={async () => {
								setInErrorState(false);
								return Promise.resolve();
							}}
						/>
					)}
				</div>

				<Loader show={showLoader} />
			</main>
		</Fragment>
	);
};
