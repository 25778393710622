/* eslint-disable react/react-in-jsx-scope */
import { h } from "preact";
import { Modal } from "./modal";

export interface UserForDeactivation {
	id: string;
	name: string;
	emailAddress: string;
}

export const ConfirmDeactivation = ({
	onCancel,
	onConfirm,
	user,
}: {
	onCancel: () => void;
	onConfirm: (user: UserForDeactivation) => void;
	user: UserForDeactivation;
}): h.JSX.Element => {
	const confirm = () => {
		onConfirm(user);
	};

	return (
		<Modal
			closeFunc={async () => {
				onCancel();
				return Promise.resolve();
			}}
			canExit={true}
		>
			<div id="confirmAccountDeactivationModal">
				<h2>Do you really want to deactivate {user.name}?</h2>

				<p>
					Deactivating an account will completely disable their account and
					delete all account recovery data for the account. Are you sure you
					want to continue?
				</p>

				<div style={{ textAlign: "center" }}>
					<button
						className="secondaryButton"
						style={{ margin: "15px" }}
						onClick={onCancel}
					>
						Cancel
					</button>
					<button
						className="primaryButton"
						style={{ margin: "15px" }}
						onClick={confirm}
					>
						Disable the Account
					</button>
				</div>
			</div>
		</Modal>
	);
};
