/* eslint-disable react/react-in-jsx-scope */
import { h } from "preact";
import { StoreProvider } from "@preact-hooks/unistore";
import { Router } from "preact-router";

import { AdminClient } from "../../client";
import { AuthClientContext, renderShell } from "../../../../lib/init";
import { AdminClientContext } from "./components/AdminClientContext";
import { store } from "../../../../lib/init/store";
import { WithIdleTimer } from "../../../../lib/components/WithIdleTimer";
import { LoginPage } from "../../../../lib/components/login";
import { Navbar } from "../../../../lib/components/legal-nav";
import { AdminDashboardPage } from "./pages/dashboard";
import { AdminAccountSettingsPage } from "./pages/account-settings";
import { AuthRouter } from "../../../../lib/components/AuthRouter";
import { AdminCreateCredentials } from "./pages/create-credentials";
import { AdminSignupToken } from "./components/sign-up-token";
import { Admins } from "./pages/admins";
import { EnterBackupCodesPage } from "../../../../lib/components/backup-codes/recover";
import { ComposeEmail } from "./pages/compose-email";
import { useEffect, useState } from "preact/hooks";
import { Dlocs } from "./pages/dlocs";
import { ParseAccountRecoverytoken } from "../../../../lib/components/ParseAccountRecoveryToken";
import { AnswerSecurityQuestions } from "../../../../lib/components/security-questions/AnswerSecurityQuestions";
import { RecoverySetup } from "../../../../lib/components/recovery-setup";

const client = new AdminClient();

const Main = () => {
	const [envVars, setEnvVars] = useState<Record<string, string> | null>(null);

	useEffect(() => {
		const getEnvVars = async () => {
			setEnvVars(await client.getEnvironmentVariables());
		};

		if (!envVars) {
			void getEnvVars();
		}
	}, []);

	return (
		<AuthClientContext.Provider value={client}>
			<AdminClientContext.Provider value={client}>
				<StoreProvider value={store}>
					<WithIdleTimer>
						<Router>
							<LoginPage
								path="/"
								target="/dashboard"
								client={client}
								portalName="Admin Portal"
							/>
							<AdminCreateCredentials
								path="/create-credentials"
								client={client}
							/>
							<AdminSignupToken path="create-account/token/:token" />
							<AdminSignupToken path="create-account/token" />
							<EnterBackupCodesPage path="/reset-password" client={client} />
							<ParseAccountRecoverytoken
								path="/recover-account/token/:token"
								client={client}
							/>
							<AnswerSecurityQuestions
								client={client}
								type="dloc"
								path="/recover-account/security-questions"
							/>
							<AuthRouter path="/:rest+">
								<AdminDashboardPage path="/dashboard" />
								<AdminAccountSettingsPage path="/account" />
								<Admins path="/admins" />
								<Dlocs path="/dlocs" />
								<ComposeEmail path="/compose-email" />
								<RecoverySetup client={client} path={"/setup-recovery"} />
							</AuthRouter>
						</Router>
						<Navbar
							navItems={[
								{ title: "Admins", url: "admins", newTab: false },
								{ title: "Dlocs", url: "dlocs", newTab: false },
							]}
						/>
					</WithIdleTimer>
				</StoreProvider>
			</AdminClientContext.Provider>
		</AuthClientContext.Provider>
	);
};

void renderShell(<Main />, client);
