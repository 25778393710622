"use strict";
// Copyright 2021-2023 Buf Technologies, Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
Object.defineProperty(exports, "__esModule", { value: true });
exports.runStreaming = exports.runUnary = void 0;
/**
 * Runs a unary method with the given interceptors. Note that this function
 * is only used when implementing a Transport.
 *
 * @deprecated Use runUnaryCall from @bufbuild/connect/protocol instead.
 */
function runUnary(req, next, interceptors) {
    if (interceptors) {
        next = applyInterceptors(next, interceptors);
    }
    return next(req);
}
exports.runUnary = runUnary;
/**
 * Runs a server-streaming method with the given interceptors. Note that this
 * function is only used when implementing a Transport.
 *
 * @deprecated Use runStreamingCall from @bufbuild/connect/protocol instead.
 */
function runStreaming(req, next, interceptors) {
    if (interceptors) {
        next = applyInterceptors(next, interceptors);
    }
    return next(req);
}
exports.runStreaming = runStreaming;
/**
 * applyInterceptors takes the given UnaryFn or ServerStreamingFn, and wraps
 * it with each of the given interceptors, returning a new UnaryFn or
 * ServerStreamingFn.
 */
function applyInterceptors(next, interceptors) {
    return interceptors
        .concat()
        .reverse()
        .reduce(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    (n, i) => i(n), next // eslint-disable-line @typescript-eslint/no-explicit-any
    );
}
