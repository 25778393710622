"use strict";
// Copyright 2021-2023 Buf Technologies, Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
Object.defineProperty(exports, "__esModule", { value: true });
exports.codeToHttpStatus = exports.codeFromHttpStatus = void 0;
const code_js_1 = require("../code.js");
/**
 * Determine the Connect error code for the given HTTP status code.
 * See https://connect.build/docs/protocol#error-codes
 *
 * @private Internal code, does not follow semantic versioning.
 */
function codeFromHttpStatus(httpStatus) {
    switch (httpStatus) {
        case 400: // Bad Request
            return code_js_1.Code.InvalidArgument;
        case 401: // Unauthorized
            return code_js_1.Code.Unauthenticated;
        case 403: // Forbidden
            return code_js_1.Code.PermissionDenied;
        case 404: // Not Found
            return code_js_1.Code.Unimplemented;
        case 408: // Request Timeout
            return code_js_1.Code.DeadlineExceeded;
        case 409: // Conflict
            return code_js_1.Code.Aborted;
        case 412: // Precondition Failed
            return code_js_1.Code.FailedPrecondition;
        case 413: // Payload Too Large
            return code_js_1.Code.ResourceExhausted;
        case 415: // Unsupported Media Type
            return code_js_1.Code.Internal;
        case 429: // Too Many Requests
            return code_js_1.Code.Unavailable;
        case 431: // Request Header Fields Too Large
            return code_js_1.Code.ResourceExhausted;
        case 502: // Bad Gateway
            return code_js_1.Code.Unavailable;
        case 503: // Service Unavailable
            return code_js_1.Code.Unavailable;
        case 504: // Gateway Timeout
            return code_js_1.Code.Unavailable;
        default:
            return code_js_1.Code.Unknown;
    }
}
exports.codeFromHttpStatus = codeFromHttpStatus;
/**
 * Returns a HTTP status code for the given Connect code.
 * See https://connect.build/docs/protocol#error-codes
 *
 * @private Internal code, does not follow semantic versioning.
 */
function codeToHttpStatus(code) {
    switch (code) {
        case code_js_1.Code.Canceled:
            return 408; // Request Timeout
        case code_js_1.Code.Unknown:
            return 500; // Internal Server Error
        case code_js_1.Code.InvalidArgument:
            return 400; // Bad Request
        case code_js_1.Code.DeadlineExceeded:
            return 408; // Request Timeout
        case code_js_1.Code.NotFound:
            return 404; // Not Found
        case code_js_1.Code.AlreadyExists:
            return 409; // Conflict
        case code_js_1.Code.PermissionDenied:
            return 403; // Forbidden
        case code_js_1.Code.ResourceExhausted:
            return 429; // Too Many Requests
        case code_js_1.Code.FailedPrecondition:
            return 412; // Precondition Failed
        case code_js_1.Code.Aborted:
            return 409; // Conflict
        case code_js_1.Code.OutOfRange:
            return 400; // Bad Request
        case code_js_1.Code.Unimplemented:
            return 404; // Not Found
        case code_js_1.Code.Internal:
            return 500; // Internal Server Error
        case code_js_1.Code.Unavailable:
            return 503; // Service Unavailable
        case code_js_1.Code.DataLoss:
            return 500; // Internal Server Error
        case code_js_1.Code.Unauthenticated:
            return 401; // Unauthorized
        default:
            return 500; // same as CodeUnknown
    }
}
exports.codeToHttpStatus = codeToHttpStatus;
