// @generated by protoc-gen-connect-es v0.11.0 with parameter "target=ts,import_extension=none"
// @generated from file recovery.proto (package org.callisto.recovery.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  Delete_By_Proxy_Request,
  Delete_By_Proxy_Response,
  Delete_Request,
  Delete_Response,
  OPRFRequest,
  OPRFResponse,
  Recovery_POPRFRequest,
  Recovery_POPRFResponse,
  Recovery_StartRequest,
  Recovery_StartResponse,
  Recovery_ValidateTokenRequest,
  Recovery_ValidateTokenResponse,
  Request_StartRequest,
  Request_StartResponse,
  Setup_POPRFRequest,
  Setup_POPRFResponse,
  Setup_SaveRequest,
  Setup_SaveResponse,
  Update_Request,
  Update_Response
} from './recovery_pb';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * This gRPC service is accessible to outside callers, and implements the
 * client-visible parts of the recovery process.
 *
 * Common ==================================================================
 *
 * @generated from service org.callisto.recovery.v1.RecoveryService
 */
export const RecoveryService = {
  typeName: "org.callisto.recovery.v1.RecoveryService",
  methods: {
    /**
     * oprf implements an OPRF endpoint with a static exponent.
     *
     * @generated from rpc org.callisto.recovery.v1.RecoveryService.oprf
     */
    oprf: {
      name: "oprf",
      I: OPRFRequest,
      O: OPRFResponse,
      kind: MethodKind.Unary,
    },
    /**
     * setup_POPRF executes an OPRF using an exponent that is provided by the
     * user at setup time, which should be stored later.
     *
     * @generated from rpc org.callisto.recovery.v1.RecoveryService.setup_POPRF
     */
    setup_POPRF: {
      name: "setup_POPRF",
      I: Setup_POPRFRequest,
      O: Setup_POPRFResponse,
      kind: MethodKind.Unary,
    },
    /**
     * setup_Save saves the parameters computed by the user, which are needed
     * later to complete the recovery process.
     *
     * @generated from rpc org.callisto.recovery.v1.RecoveryService.setup_Save
     */
    setup_Save: {
      name: "setup_Save",
      I: Setup_SaveRequest,
      O: Setup_SaveResponse,
      kind: MethodKind.Unary,
    },
    /**
     * request_Start decrypts the contact info for the user and potentially
     * sends an email with a token, used in the final steps of the recovery
     * process.
     *
     * @generated from rpc org.callisto.recovery.v1.RecoveryService.request_Start
     */
    request_Start: {
      name: "request_Start",
      I: Request_StartRequest,
      O: Request_StartResponse,
      kind: MethodKind.Unary,
    },
    /**
     * recovery_Start takes a token and starts a "transaction", which is used
     * to ensure that both servers have received and validated the user's input.
     *
     * @generated from rpc org.callisto.recovery.v1.RecoveryService.recovery_Start
     */
    recovery_Start: {
      name: "recovery_Start",
      I: Recovery_StartRequest,
      O: Recovery_StartResponse,
      kind: MethodKind.Unary,
    },
    /**
     * recovery_ValidateToken is intended to be called from a separate server
     * than recovery_Start was, and checks that the user has provided the same,
     * valid token to each server. Once it has, it returns the necessary
     * parameters for them to complete recovery and decrypt their envelope.
     *
     * @generated from rpc org.callisto.recovery.v1.RecoveryService.recovery_ValidateToken
     */
    recovery_ValidateToken: {
      name: "recovery_ValidateToken",
      I: Recovery_ValidateTokenRequest,
      O: Recovery_ValidateTokenResponse,
      kind: MethodKind.Unary,
    },
    /**
     * recovery_POPRF implements an OPRF using an exponent that is looked up
     * in the local database.
     *
     * @generated from rpc org.callisto.recovery.v1.RecoveryService.recovery_POPRF
     */
    recovery_POPRF: {
      name: "recovery_POPRF",
      I: Recovery_POPRFRequest,
      O: Recovery_POPRFResponse,
      kind: MethodKind.Unary,
    },
    /**
     * delete removes recovery information from the database.
     *
     * @generated from rpc org.callisto.recovery.v1.RecoveryService.delete
     */
    delete: {
      name: "delete",
      I: Delete_Request,
      O: Delete_Response,
      kind: MethodKind.Unary,
    },
    /**
     * delete_By_Proxy removes recovery information from the database when requested by a DLOC or Admin
     *
     * @generated from rpc org.callisto.recovery.v1.RecoveryService.delete_By_Proxy
     */
    delete_By_Proxy: {
      name: "delete_By_Proxy",
      I: Delete_By_Proxy_Request,
      O: Delete_By_Proxy_Response,
      kind: MethodKind.Unary,
    },
    /**
     * update updates the survivor's recovery contact information.
     *
     * @generated from rpc org.callisto.recovery.v1.RecoveryService.update
     */
    update: {
      name: "update",
      I: Update_Request,
      O: Update_Response,
      kind: MethodKind.Unary,
    },
  }
} as const;

