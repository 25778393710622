"use strict";
// Copyright 2021-2023 Buf Technologies, Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
var __await = (this && this.__await) || function (v) { return this instanceof __await ? (this.v = v, this) : new __await(v); }
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
var __asyncDelegator = (this && this.__asyncDelegator) || function (o) {
    var i, p;
    return i = {}, verb("next"), verb("throw", function (e) { throw e; }), verb("return"), i[Symbol.iterator] = function () { return this; }, i;
    function verb(n, f) { i[n] = o[n] ? function (v) { return (p = !p) ? { value: __await(o[n](v)), done: false } : f ? f(v) : v; } : f; }
};
var __asyncGenerator = (this && this.__asyncGenerator) || function (thisArg, _arguments, generator) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var g = generator.apply(thisArg, _arguments || []), i, q = [];
    return i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i;
    function verb(n) { if (g[n]) i[n] = function (v) { return new Promise(function (a, b) { q.push([n, v, a, b]) > 1 || resume(n, v); }); }; }
    function resume(n, v) { try { step(g[n](v)); } catch (e) { settle(q[0][3], e); } }
    function step(r) { r.value instanceof __await ? Promise.resolve(r.value.v).then(fulfill, reject) : settle(q[0][2], r); }
    function fulfill(value) { resume("next", value); }
    function reject(value) { resume("throw", value); }
    function settle(f, v) { if (f(v), q.shift(), q.length) resume(q[0][0], q[0][1]); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createBiDiStreamingFn = exports.createClientStreamingFn = exports.createServerStreamingFn = exports.createPromiseClient = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const any_client_js_1 = require("./any-client.js");
const connect_error_js_1 = require("./connect-error.js");
const code_js_1 = require("./code.js");
const async_iterable_js_1 = require("./protocol/async-iterable.js");
/**
 * Create a PromiseClient for the given service, invoking RPCs through the
 * given transport.
 */
function createPromiseClient(service, transport) {
    return (0, any_client_js_1.makeAnyClient)(service, (method) => {
        switch (method.kind) {
            case protobuf_1.MethodKind.Unary:
                return createUnaryFn(transport, service, method);
            case protobuf_1.MethodKind.ServerStreaming:
                return createServerStreamingFn(transport, service, method);
            case protobuf_1.MethodKind.ClientStreaming:
                return createClientStreamingFn(transport, service, method);
            case protobuf_1.MethodKind.BiDiStreaming:
                return createBiDiStreamingFn(transport, service, method);
            default:
                return null;
        }
    });
}
exports.createPromiseClient = createPromiseClient;
function createUnaryFn(transport, service, method) {
    return async function (input, options) {
        var _a, _b;
        const response = await transport.unary(service, method, options === null || options === void 0 ? void 0 : options.signal, options === null || options === void 0 ? void 0 : options.timeoutMs, options === null || options === void 0 ? void 0 : options.headers, input);
        (_a = options === null || options === void 0 ? void 0 : options.onHeader) === null || _a === void 0 ? void 0 : _a.call(options, response.header);
        (_b = options === null || options === void 0 ? void 0 : options.onTrailer) === null || _b === void 0 ? void 0 : _b.call(options, response.trailer);
        return response.message;
    };
}
function createServerStreamingFn(transport, service, method) {
    return function (input, options) {
        var _a, _b;
        return __asyncGenerator(this, arguments, function* () {
            const inputMessage = input instanceof method.I ? input : new method.I(input);
            const response = yield __await(transport.stream(service, method, options === null || options === void 0 ? void 0 : options.signal, options === null || options === void 0 ? void 0 : options.timeoutMs, options === null || options === void 0 ? void 0 : options.headers, (0, async_iterable_js_1.createAsyncIterable)([inputMessage])));
            (_a = options === null || options === void 0 ? void 0 : options.onHeader) === null || _a === void 0 ? void 0 : _a.call(options, response.header);
            yield __await(yield* __asyncDelegator(__asyncValues(response.message)));
            (_b = options === null || options === void 0 ? void 0 : options.onTrailer) === null || _b === void 0 ? void 0 : _b.call(options, response.trailer);
        });
    };
}
exports.createServerStreamingFn = createServerStreamingFn;
function createClientStreamingFn(transport, service, method) {
    return async function (request, options) {
        var _a, e_1, _b, _c;
        var _d, _e;
        function input() {
            return __asyncGenerator(this, arguments, function* input_1() {
                var _a, e_2, _b, _c;
                try {
                    for (var _d = true, request_1 = __asyncValues(request), request_1_1; request_1_1 = yield __await(request_1.next()), _a = request_1_1.done, !_a;) {
                        _c = request_1_1.value;
                        _d = false;
                        try {
                            const partial = _c;
                            yield yield __await(partial instanceof method.I ? partial : new method.I(partial));
                        }
                        finally {
                            _d = true;
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (!_d && !_a && (_b = request_1.return)) yield __await(_b.call(request_1));
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            });
        }
        const response = await transport.stream(service, method, options === null || options === void 0 ? void 0 : options.signal, options === null || options === void 0 ? void 0 : options.timeoutMs, options === null || options === void 0 ? void 0 : options.headers, input());
        (_d = options === null || options === void 0 ? void 0 : options.onHeader) === null || _d === void 0 ? void 0 : _d.call(options, response.header);
        let singleMessage;
        try {
            for (var _f = true, _g = __asyncValues(response.message), _h; _h = await _g.next(), _a = _h.done, !_a;) {
                _c = _h.value;
                _f = false;
                try {
                    const message = _c;
                    singleMessage = message;
                }
                finally {
                    _f = true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (!_f && !_a && (_b = _g.return)) await _b.call(_g);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (!singleMessage) {
            throw new connect_error_js_1.ConnectError("protocol error: missing response message", code_js_1.Code.Internal);
        }
        (_e = options === null || options === void 0 ? void 0 : options.onTrailer) === null || _e === void 0 ? void 0 : _e.call(options, response.trailer);
        return singleMessage;
    };
}
exports.createClientStreamingFn = createClientStreamingFn;
function createBiDiStreamingFn(transport, service, method) {
    return function (request, options) {
        var _a, _b;
        return __asyncGenerator(this, arguments, function* () {
            function input() {
                return __asyncGenerator(this, arguments, function* input_2() {
                    var _a, e_3, _b, _c;
                    try {
                        for (var _d = true, request_2 = __asyncValues(request), request_2_1; request_2_1 = yield __await(request_2.next()), _a = request_2_1.done, !_a;) {
                            _c = request_2_1.value;
                            _d = false;
                            try {
                                const partial = _c;
                                yield yield __await(partial instanceof method.I ? partial : new method.I(partial));
                            }
                            finally {
                                _d = true;
                            }
                        }
                    }
                    catch (e_3_1) { e_3 = { error: e_3_1 }; }
                    finally {
                        try {
                            if (!_d && !_a && (_b = request_2.return)) yield __await(_b.call(request_2));
                        }
                        finally { if (e_3) throw e_3.error; }
                    }
                });
            }
            const response = yield __await(transport.stream(service, method, options === null || options === void 0 ? void 0 : options.signal, options === null || options === void 0 ? void 0 : options.timeoutMs, options === null || options === void 0 ? void 0 : options.headers, input()));
            (_a = options === null || options === void 0 ? void 0 : options.onHeader) === null || _a === void 0 ? void 0 : _a.call(options, response.header);
            yield __await(yield* __asyncDelegator(__asyncValues(response.message)));
            (_b = options === null || options === void 0 ? void 0 : options.onTrailer) === null || _b === void 0 ? void 0 : _b.call(options, response.trailer);
        });
    };
}
exports.createBiDiStreamingFn = createBiDiStreamingFn;
