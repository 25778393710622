/* eslint-disable react/react-in-jsx-scope */
import { Fragment, h } from "preact";
import { useContext, useEffect, useState } from "preact/hooks";
import "./styles.scss";
import { AdminClientContext } from "../../components/AdminClientContext";
import { KeyGenModal } from "../../../../../../lib/components/key-generation-modal";
import { Toast } from "../../../../../../lib/components/toast";
import { useAdmins } from "../../adminData";
import { route } from "preact-router";
import { accountRecoveryIsSetUp } from "../../../../../../lib/data";

// eslint-disable-next-line no-empty-pattern
export const AdminDashboardPage = ({}: { path?: string }): h.JSX.Element => {
	const client = useContext(AdminClientContext);
	const { admins } = useAdmins();
	const [showKeyGenModal, setShowKeyGenModal] = useState<boolean>(false);
	const [inErrorState, setInErrorState] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [keySuccessfullyGenerated, setKeySuccessfullyGenerated] =
		useState<boolean>(false);

	useEffect(() => {
		if (!accountRecoveryIsSetUp(client)) {
			route("/setup-recovery");
		}
	}, [client.envVars]);

	const handleGenerateKey = async () => {
		try {
			await client.generateSharedKey();
			setKeySuccessfullyGenerated(true);
		} catch (error) {
			setInErrorState(true);
			setErrorMessage((error as Error).message);
		} finally {
			setShowKeyGenModal(false);
		}
	};

	return (
		<Fragment>
			<main id="dashboard">
				<div id="dashCard">
					<h1>Admin Dashboard</h1>
					<p>
						<a className="primary" href="compose-email">
							Send a broadcast email
						</a>
					</p>
					<p>
						<a href="admins">Add or activate a new admin</a>
					</p>
					<p>
						<a
							href="dashboard"
							onClick={(e) => {
								e.preventDefault();
								if (
									admins.length === 1 ||
									Object.keys(client.versionedKeys).length > 0
								) {
									setShowKeyGenModal(true);
								} else {
									setInErrorState(true);
									setErrorMessage(
										"You need to be activated by another admin before you can generate a key",
									);
								}
							}}
						>
							Generate a new shared admin key
						</a>
					</p>
				</div>
			</main>

			{showKeyGenModal && (
				<KeyGenModal
					onClose={() => setShowKeyGenModal(false)}
					onAccept={handleGenerateKey}
				/>
			)}

			{keySuccessfullyGenerated && (
				<Toast
					error={false}
					message="New Shared Key Generated"
					closeFunc={async () =>
						Promise.resolve(setKeySuccessfullyGenerated(false))
					}
				/>
			)}

			{inErrorState && (
				<Toast
					error={true}
					message={errorMessage}
					closeFunc={async () => Promise.resolve(setInErrorState(false))}
				/>
			)}
		</Fragment>
	);
};
