// @generated by protoc-gen-es v1.4.2 with parameter "target=ts,import_extension=none"
// @generated from file recovery.proto (package org.callisto.recovery.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage
} from '@bufbuild/protobuf';
import {
  Message,
  proto3
} from '@bufbuild/protobuf';

/**
 * @generated from message org.callisto.recovery.v1.OPRFRequest
 */
export class OPRFRequest extends Message<OPRFRequest> {
  /**
   * This is a blinded input provided by the user, which will be raised to
   * the static exponent the server keeps.
   *
   * @generated from field: bytes alpha = 1;
   */
  alpha = new Uint8Array(0);

  constructor(data?: PartialMessage<OPRFRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "org.callisto.recovery.v1.OPRFRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "alpha", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OPRFRequest {
    return new OPRFRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OPRFRequest {
    return new OPRFRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OPRFRequest {
    return new OPRFRequest().fromJsonString(jsonString, options);
  }

  static equals(a: OPRFRequest | PlainMessage<OPRFRequest> | undefined, b: OPRFRequest | PlainMessage<OPRFRequest> | undefined): boolean {
    return proto3.util.equals(OPRFRequest, a, b);
  }
}

/**
 * @generated from message org.callisto.recovery.v1.OPRFResponse
 */
export class OPRFResponse extends Message<OPRFResponse> {
  /**
   * This is the multiplied input. The user will unblind this to retrieve
   * the final output.
   *
   * @generated from field: bytes beta = 1;
   */
  beta = new Uint8Array(0);

  constructor(data?: PartialMessage<OPRFResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "org.callisto.recovery.v1.OPRFResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "beta", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OPRFResponse {
    return new OPRFResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OPRFResponse {
    return new OPRFResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OPRFResponse {
    return new OPRFResponse().fromJsonString(jsonString, options);
  }

  static equals(a: OPRFResponse | PlainMessage<OPRFResponse> | undefined, b: OPRFResponse | PlainMessage<OPRFResponse> | undefined): boolean {
    return proto3.util.equals(OPRFResponse, a, b);
  }
}

/**
 * @generated from message org.callisto.recovery.v1.Setup_POPRFRequest
 */
export class Setup_POPRFRequest extends Message<Setup_POPRFRequest> {
  /**
   * This is the blinded input provided by the user, which will be raised to
   * an exponent derived from n and the server's OPRF key.
   *
   * @generated from field: bytes alpha = 1;
   */
  alpha = new Uint8Array(0);

  /**
   * This is a unique nonce provided by the user, which will be used to
   * derive the actual exponent for OPRF.
   *
   * @generated from field: bytes n = 2;
   */
  n = new Uint8Array(0);

  constructor(data?: PartialMessage<Setup_POPRFRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "org.callisto.recovery.v1.Setup_POPRFRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "alpha", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 2, name: "n", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Setup_POPRFRequest {
    return new Setup_POPRFRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Setup_POPRFRequest {
    return new Setup_POPRFRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Setup_POPRFRequest {
    return new Setup_POPRFRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Setup_POPRFRequest | PlainMessage<Setup_POPRFRequest> | undefined, b: Setup_POPRFRequest | PlainMessage<Setup_POPRFRequest> | undefined): boolean {
    return proto3.util.equals(Setup_POPRFRequest, a, b);
  }
}

/**
 * @generated from message org.callisto.recovery.v1.Setup_POPRFResponse
 */
export class Setup_POPRFResponse extends Message<Setup_POPRFResponse> {
  /**
   * This is the multiplied output. The user will unblind this to retrieve
   * the final output.
   *
   * @generated from field: bytes beta = 1;
   */
  beta = new Uint8Array(0);

  constructor(data?: PartialMessage<Setup_POPRFResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "org.callisto.recovery.v1.Setup_POPRFResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "beta", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Setup_POPRFResponse {
    return new Setup_POPRFResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Setup_POPRFResponse {
    return new Setup_POPRFResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Setup_POPRFResponse {
    return new Setup_POPRFResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Setup_POPRFResponse | PlainMessage<Setup_POPRFResponse> | undefined, b: Setup_POPRFResponse | PlainMessage<Setup_POPRFResponse> | undefined): boolean {
    return proto3.util.equals(Setup_POPRFResponse, a, b);
  }
}

/**
 * @generated from message org.callisto.recovery.v1.Setup_SaveRequest
 */
export class Setup_SaveRequest extends Message<Setup_SaveRequest> {
  /**
   * This is an ID, computed by the user to make fast lookups possible.
   *
   * @generated from field: bytes id = 1;
   */
  id = new Uint8Array(0);

  /**
   * This is the encrypted contact information.
   *
   * @generated from field: bytes eE = 2;
   */
  eE = new Uint8Array(0);

  /**
   * This is the secret share of the key to the contact information, which
   * must be different for each server this is sent to.
   *
   * @generated from field: bytes kE = 3;
   */
  kE = new Uint8Array(0);

  /**
   * This is the encrypted envelope, which contains the user's keys.
   *
   * @generated from field: bytes eA = 4;
   */
  eA = new Uint8Array(0);

  /**
   * This is the secret share of the key to the envelope, which must be
   * different for each server this is sent to.
   *
   * @generated from field: bytes kA = 5;
   */
  kA = new Uint8Array(0);

  /**
   * This is the user's unique exponent, which is later used for pOPRF.
   *
   * @generated from field: bytes n = 6;
   */
  n = new Uint8Array(0);

  /**
   * This is the public key generated for the user, which will be used
   * to sign a request to delete the recovery info.
   *
   * @generated from field: bytes pk = 7;
   */
  pk = new Uint8Array(0);

  constructor(data?: PartialMessage<Setup_SaveRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "org.callisto.recovery.v1.Setup_SaveRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 2, name: "eE", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 3, name: "kE", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 4, name: "eA", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 5, name: "kA", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 6, name: "n", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 7, name: "pk", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Setup_SaveRequest {
    return new Setup_SaveRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Setup_SaveRequest {
    return new Setup_SaveRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Setup_SaveRequest {
    return new Setup_SaveRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Setup_SaveRequest | PlainMessage<Setup_SaveRequest> | undefined, b: Setup_SaveRequest | PlainMessage<Setup_SaveRequest> | undefined): boolean {
    return proto3.util.equals(Setup_SaveRequest, a, b);
  }
}

/**
 * @generated from message org.callisto.recovery.v1.Setup_SaveResponse
 */
export class Setup_SaveResponse extends Message<Setup_SaveResponse> {
  constructor(data?: PartialMessage<Setup_SaveResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "org.callisto.recovery.v1.Setup_SaveResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Setup_SaveResponse {
    return new Setup_SaveResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Setup_SaveResponse {
    return new Setup_SaveResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Setup_SaveResponse {
    return new Setup_SaveResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Setup_SaveResponse | PlainMessage<Setup_SaveResponse> | undefined, b: Setup_SaveResponse | PlainMessage<Setup_SaveResponse> | undefined): boolean {
    return proto3.util.equals(Setup_SaveResponse, a, b);
  }
}

/**
 * @generated from message org.callisto.recovery.v1.Request_StartRequest
 */
export class Request_StartRequest extends Message<Request_StartRequest> {
  /**
   * This is the ID computed by the user from their recovery email and phone.
   *
   * @generated from field: bytes id = 1;
   */
  id = new Uint8Array(0);

  /**
   * This is the one-time-pad secret share computed from their recovery email
   * and phone, which is provided to the server so that it can decrypt the
   * contact info.
   *
   * @generated from field: bytes kOTPE = 2;
   */
  kOTPE = new Uint8Array(0);

  /**
   * This is the environment variable that will be used to lookup what canonical
   * domain to use for the tokenized link in the email.
   *
   * @generated from field: string mailDomainKey = 3;
   */
  mailDomainKey = "";

  constructor(data?: PartialMessage<Request_StartRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "org.callisto.recovery.v1.Request_StartRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 2, name: "kOTPE", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 3, name: "mailDomainKey", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Request_StartRequest {
    return new Request_StartRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Request_StartRequest {
    return new Request_StartRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Request_StartRequest {
    return new Request_StartRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Request_StartRequest | PlainMessage<Request_StartRequest> | undefined, b: Request_StartRequest | PlainMessage<Request_StartRequest> | undefined): boolean {
    return proto3.util.equals(Request_StartRequest, a, b);
  }
}

/**
 * @generated from message org.callisto.recovery.v1.Request_StartResponse
 */
export class Request_StartResponse extends Message<Request_StartResponse> {
  constructor(data?: PartialMessage<Request_StartResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "org.callisto.recovery.v1.Request_StartResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Request_StartResponse {
    return new Request_StartResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Request_StartResponse {
    return new Request_StartResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Request_StartResponse {
    return new Request_StartResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Request_StartResponse | PlainMessage<Request_StartResponse> | undefined, b: Request_StartResponse | PlainMessage<Request_StartResponse> | undefined): boolean {
    return proto3.util.equals(Request_StartResponse, a, b);
  }
}

/**
 * @generated from message org.callisto.recovery.v1.Recovery_StartRequest
 */
export class Recovery_StartRequest extends Message<Recovery_StartRequest> {
  /**
   * This is the token from the user's email.
   *
   * @generated from field: string token = 1;
   */
  token = "";

  constructor(data?: PartialMessage<Recovery_StartRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "org.callisto.recovery.v1.Recovery_StartRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Recovery_StartRequest {
    return new Recovery_StartRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Recovery_StartRequest {
    return new Recovery_StartRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Recovery_StartRequest {
    return new Recovery_StartRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Recovery_StartRequest | PlainMessage<Recovery_StartRequest> | undefined, b: Recovery_StartRequest | PlainMessage<Recovery_StartRequest> | undefined): boolean {
    return proto3.util.equals(Recovery_StartRequest, a, b);
  }
}

/**
 * @generated from message org.callisto.recovery.v1.Recovery_StartResponse
 */
export class Recovery_StartResponse extends Message<Recovery_StartResponse> {
  /**
   * This is a transaction ID, indicating that the token is valid and that
   * further operations on it can commence.
   *
   * @generated from field: string transaction = 1;
   */
  transaction = "";

  constructor(data?: PartialMessage<Recovery_StartResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "org.callisto.recovery.v1.Recovery_StartResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "transaction", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Recovery_StartResponse {
    return new Recovery_StartResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Recovery_StartResponse {
    return new Recovery_StartResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Recovery_StartResponse {
    return new Recovery_StartResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Recovery_StartResponse | PlainMessage<Recovery_StartResponse> | undefined, b: Recovery_StartResponse | PlainMessage<Recovery_StartResponse> | undefined): boolean {
    return proto3.util.equals(Recovery_StartResponse, a, b);
  }
}

/**
 * @generated from message org.callisto.recovery.v1.Recovery_ValidateTokenRequest
 */
export class Recovery_ValidateTokenRequest extends Message<Recovery_ValidateTokenRequest> {
  /**
   * This is the token from the user's email.
   *
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * This is the transaction ID provided by recovery_Start, which is sent to
   * the other server to assist in validating that both have received the
   * correct token.
   *
   * @generated from field: string transaction = 2;
   */
  transaction = "";

  constructor(data?: PartialMessage<Recovery_ValidateTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "org.callisto.recovery.v1.Recovery_ValidateTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "transaction", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Recovery_ValidateTokenRequest {
    return new Recovery_ValidateTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Recovery_ValidateTokenRequest {
    return new Recovery_ValidateTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Recovery_ValidateTokenRequest {
    return new Recovery_ValidateTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Recovery_ValidateTokenRequest | PlainMessage<Recovery_ValidateTokenRequest> | undefined, b: Recovery_ValidateTokenRequest | PlainMessage<Recovery_ValidateTokenRequest> | undefined): boolean {
    return proto3.util.equals(Recovery_ValidateTokenRequest, a, b);
  }
}

/**
 * @generated from message org.callisto.recovery.v1.Recovery_ValidateTokenResponse
 */
export class Recovery_ValidateTokenResponse extends Message<Recovery_ValidateTokenResponse> {
  /**
   * This is the list of security questions originally picked by the user.
   *
   * @generated from field: repeated string questions = 1;
   */
  questions: string[] = [];

  /**
   * This is the encrypted envelope.
   *
   * @generated from field: bytes eA = 2;
   */
  eA = new Uint8Array(0);

  /**
   * These are the secret shares that the server keeps, which when combined
   * with the one-time pad the user will calculate, can reconstruct the key
   * to the envelope.
   *
   * @generated from field: bytes kA1 = 3;
   */
  kA1 = new Uint8Array(0);

  /**
   * @generated from field: bytes kA2 = 4;
   */
  kA2 = new Uint8Array(0);

  /**
   * This is a one-time pad for the answers to the security questions.
   *
   * @generated from field: bytes m = 5;
   */
  m = new Uint8Array(0);

  constructor(data?: PartialMessage<Recovery_ValidateTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "org.callisto.recovery.v1.Recovery_ValidateTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "questions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "eA", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 3, name: "kA1", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 4, name: "kA2", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 5, name: "m", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Recovery_ValidateTokenResponse {
    return new Recovery_ValidateTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Recovery_ValidateTokenResponse {
    return new Recovery_ValidateTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Recovery_ValidateTokenResponse {
    return new Recovery_ValidateTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Recovery_ValidateTokenResponse | PlainMessage<Recovery_ValidateTokenResponse> | undefined, b: Recovery_ValidateTokenResponse | PlainMessage<Recovery_ValidateTokenResponse> | undefined): boolean {
    return proto3.util.equals(Recovery_ValidateTokenResponse, a, b);
  }
}

/**
 * @generated from message org.callisto.recovery.v1.Recovery_POPRFRequest
 */
export class Recovery_POPRFRequest extends Message<Recovery_POPRFRequest> {
  /**
   * This is the token from the user's email.
   *
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * This is the transaction ID provided by recovery_Start, which validates
   * that the user has already provided a working token to both servers.
   *
   * @generated from field: string transaction = 2;
   */
  transaction = "";

  /**
   * This is a blinded input from the user, which will be raised to an
   * exponent looked up in the local database.
   *
   * @generated from field: bytes alpha = 3;
   */
  alpha = new Uint8Array(0);

  constructor(data?: PartialMessage<Recovery_POPRFRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "org.callisto.recovery.v1.Recovery_POPRFRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "transaction", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "alpha", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Recovery_POPRFRequest {
    return new Recovery_POPRFRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Recovery_POPRFRequest {
    return new Recovery_POPRFRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Recovery_POPRFRequest {
    return new Recovery_POPRFRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Recovery_POPRFRequest | PlainMessage<Recovery_POPRFRequest> | undefined, b: Recovery_POPRFRequest | PlainMessage<Recovery_POPRFRequest> | undefined): boolean {
    return proto3.util.equals(Recovery_POPRFRequest, a, b);
  }
}

/**
 * @generated from message org.callisto.recovery.v1.Recovery_POPRFResponse
 */
export class Recovery_POPRFResponse extends Message<Recovery_POPRFResponse> {
  /**
   * This is the multiplied output. The user will unblind this to retrieve
   * the final output.
   *
   * @generated from field: bytes beta = 1;
   */
  beta = new Uint8Array(0);

  constructor(data?: PartialMessage<Recovery_POPRFResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "org.callisto.recovery.v1.Recovery_POPRFResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "beta", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Recovery_POPRFResponse {
    return new Recovery_POPRFResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Recovery_POPRFResponse {
    return new Recovery_POPRFResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Recovery_POPRFResponse {
    return new Recovery_POPRFResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Recovery_POPRFResponse | PlainMessage<Recovery_POPRFResponse> | undefined, b: Recovery_POPRFResponse | PlainMessage<Recovery_POPRFResponse> | undefined): boolean {
    return proto3.util.equals(Recovery_POPRFResponse, a, b);
  }
}

/**
 * Delete ======================================================================
 *
 * @generated from message org.callisto.recovery.v1.Delete_Request
 */
export class Delete_Request extends Message<Delete_Request> {
  /**
   * This is the ID computed by the user from their recovery email and phone.
   *
   * @generated from field: bytes id = 1;
   */
  id = new Uint8Array(0);

  /**
   * This is a signature to validate that the user owns this id.
   *
   * @generated from field: bytes signature = 2;
   */
  signature = new Uint8Array(0);

  constructor(data?: PartialMessage<Delete_Request>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "org.callisto.recovery.v1.Delete_Request";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 2, name: "signature", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Delete_Request {
    return new Delete_Request().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Delete_Request {
    return new Delete_Request().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Delete_Request {
    return new Delete_Request().fromJsonString(jsonString, options);
  }

  static equals(a: Delete_Request | PlainMessage<Delete_Request> | undefined, b: Delete_Request | PlainMessage<Delete_Request> | undefined): boolean {
    return proto3.util.equals(Delete_Request, a, b);
  }
}

/**
 * @generated from message org.callisto.recovery.v1.Delete_Response
 */
export class Delete_Response extends Message<Delete_Response> {
  constructor(data?: PartialMessage<Delete_Response>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "org.callisto.recovery.v1.Delete_Response";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Delete_Response {
    return new Delete_Response().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Delete_Response {
    return new Delete_Response().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Delete_Response {
    return new Delete_Response().fromJsonString(jsonString, options);
  }

  static equals(a: Delete_Response | PlainMessage<Delete_Response> | undefined, b: Delete_Response | PlainMessage<Delete_Response> | undefined): boolean {
    return proto3.util.equals(Delete_Response, a, b);
  }
}

/**
 * @generated from message org.callisto.recovery.v1.Delete_By_Proxy_Request
 */
export class Delete_By_Proxy_Request extends Message<Delete_By_Proxy_Request> {
  /**
   * This is the ID computed by the user from their recovery email and phone.
   *
   * @generated from field: bytes id = 1;
   */
  id = new Uint8Array(0);

  /**
   * This is a signature to validate that the user owns this id.
   *
   * @generated from field: bytes signature = 2;
   */
  signature = new Uint8Array(0);

  /**
   * This is the user ID for the user making the request
   *
   * @generated from field: string userId = 3;
   */
  userId = "";

  constructor(data?: PartialMessage<Delete_By_Proxy_Request>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "org.callisto.recovery.v1.Delete_By_Proxy_Request";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 2, name: "signature", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 3, name: "userId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Delete_By_Proxy_Request {
    return new Delete_By_Proxy_Request().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Delete_By_Proxy_Request {
    return new Delete_By_Proxy_Request().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Delete_By_Proxy_Request {
    return new Delete_By_Proxy_Request().fromJsonString(jsonString, options);
  }

  static equals(a: Delete_By_Proxy_Request | PlainMessage<Delete_By_Proxy_Request> | undefined, b: Delete_By_Proxy_Request | PlainMessage<Delete_By_Proxy_Request> | undefined): boolean {
    return proto3.util.equals(Delete_By_Proxy_Request, a, b);
  }
}

/**
 * @generated from message org.callisto.recovery.v1.Delete_By_Proxy_Response
 */
export class Delete_By_Proxy_Response extends Message<Delete_By_Proxy_Response> {
  constructor(data?: PartialMessage<Delete_By_Proxy_Response>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "org.callisto.recovery.v1.Delete_By_Proxy_Response";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Delete_By_Proxy_Response {
    return new Delete_By_Proxy_Response().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Delete_By_Proxy_Response {
    return new Delete_By_Proxy_Response().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Delete_By_Proxy_Response {
    return new Delete_By_Proxy_Response().fromJsonString(jsonString, options);
  }

  static equals(a: Delete_By_Proxy_Response | PlainMessage<Delete_By_Proxy_Response> | undefined, b: Delete_By_Proxy_Response | PlainMessage<Delete_By_Proxy_Response> | undefined): boolean {
    return proto3.util.equals(Delete_By_Proxy_Response, a, b);
  }
}

/**
 * Update ======================================================================
 *
 * @generated from message org.callisto.recovery.v1.Update_Request
 */
export class Update_Request extends Message<Update_Request> {
  /**
   * This is the original ID that the user had in the system, in case their
   * email/phone number changed and we need to update.
   *
   * @generated from field: bytes id = 1;
   */
  id = new Uint8Array(0);

  /**
   * This is the new ID that the user has computed from their (potentially)
   * new email/phone number. This might be the same as the original.
   *
   * @generated from field: bytes newId = 2;
   */
  newId = new Uint8Array(0);

  /**
   * This is the encrypted contact information, which might be new.
   *
   * @generated from field: bytes eE = 3;
   */
  eE = new Uint8Array(0);

  /**
   * This is the secret share of the key to the contact information, which
   * must be different for each server this is sent to.
   *
   * @generated from field: bytes kE = 4;
   */
  kE = new Uint8Array(0);

  /**
   * This is the encrypted envelope, which contains the user's keys.
   *
   * @generated from field: bytes eA = 5;
   */
  eA = new Uint8Array(0);

  /**
   * This is the secret share of the key to the envelope, which must be
   * different for each server this is sent to.
   *
   * @generated from field: bytes kA = 6;
   */
  kA = new Uint8Array(0);

  /**
   * This is a signature to validate that the user owns this id.
   *
   * @generated from field: bytes signature = 7;
   */
  signature = new Uint8Array(0);

  constructor(data?: PartialMessage<Update_Request>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "org.callisto.recovery.v1.Update_Request";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 2, name: "newId", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 3, name: "eE", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 4, name: "kE", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 5, name: "eA", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 6, name: "kA", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 7, name: "signature", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Update_Request {
    return new Update_Request().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Update_Request {
    return new Update_Request().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Update_Request {
    return new Update_Request().fromJsonString(jsonString, options);
  }

  static equals(a: Update_Request | PlainMessage<Update_Request> | undefined, b: Update_Request | PlainMessage<Update_Request> | undefined): boolean {
    return proto3.util.equals(Update_Request, a, b);
  }
}

/**
 * @generated from message org.callisto.recovery.v1.Update_Response
 */
export class Update_Response extends Message<Update_Response> {
  constructor(data?: PartialMessage<Update_Response>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "org.callisto.recovery.v1.Update_Response";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Update_Response {
    return new Update_Response().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Update_Response {
    return new Update_Response().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Update_Response {
    return new Update_Response().fromJsonString(jsonString, options);
  }

  static equals(a: Update_Response | PlainMessage<Update_Response> | undefined, b: Update_Response | PlainMessage<Update_Response> | undefined): boolean {
    return proto3.util.equals(Update_Response, a, b);
  }
}

